<template>
  <div id="app" :style="{ transform: `scale(${scale})` }">
    <section class="official-website">
      <section class="official-website-title-without">
        <section class="official-website-title"></section>
      </section>
      <section class="official-website-1-without">
        <section class="official-website-1">
          <img
            src="https://obs.shunhuishou.com/recovery/official-website/website-1.png"
            class="official-website-1-img"
          />
          <div class="official-website-1-content">
            <div class="title-box">
              <div class="official-website-1-content-title">
                顺丰旗下回收平台
              </div>
              <div class="official-website-1-content-desc">
                价格无忧 · 隐私无忧 · 收款无忧 · 丢损无忧
              </div>
            </div>
            <div class="qrcode-box">
              <div class="q-1">
                <img
                  src="https://obs.shunhuishou.com/recovery/official-website/qrcode-1.png"
                  alt=""
                  class="qrcode-icon"
                />
                <div class="t">扫码关注得大礼包</div>
              </div>
              <div class="q-2">
                <img
                  src="https://obs.shunhuishou.com/recovery/official-website/qrcode-1.png"
                  alt=""
                  class="qrcode-icon"
                />
                <div class="t">扫码估价得寄件券</div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section class="official-website-recovery-without">
        <section class="official-website-recovery">
          <div>
            <div class="title">回收方式</div>
            <div class="content-box">
              <div class="content-box-main">
                <div class="box-item">
                  <div class="box-item-main">
                    <img
                      class="post-img"
                      src="https://obs.shunhuishou.com/recovery/official-website/post.png"
                      alt=""
                    />
                    <div class="content">
                      <div>邮寄回收</div>
                      <div class="des">在线估价 顺丰取件 专业质检 极速打款</div>
                    </div>
                  </div>
                </div>
                <div class="box-item">
                  <div class="box-item-main">
                    <img
                      class="post-img"
                      src="https://obs.shunhuishou.com/recovery/official-website/visit.png"
                      alt=""
                    />
                    <div class="content">
                      <div>邮寄回收</div>
                      <div class="des">在线估价 顺丰取件 专业质检 极速打款</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section class="official-website-2-without">
        <section class="official-website-2"></section>
      </section>
      <section class="official-website-3-without">
        <section class="official-website-3"></section>
      </section>
      <section class="official-website-4-without">
        <section class="official-website-4"></section>
      </section>
      <section class="official-website-5-without">
        <section class="official-website-5">
          <div class="line" @click="operateJump">
            Copyright © 2023 All Rights Reserved. 深圳市微丰启科技有限公司
            版权所有 粤ICP备2023132607号-1
          </div>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      scale: 1,
      obs: "https://obs.shunhuishou.com/recovery/official-website/",
    };
  },
  mounted() {
    const designWidth = 1920; // 设计稿宽度
    const clientWidth = document.documentElement.clientWidth;
    this.scale = clientWidth / designWidth;

    window.addEventListener("resize", () => {
      const designWidth = 1920; // 设计稿宽度
      const clientWidth = document.documentElement.clientWidth;
      this.scale = clientWidth / designWidth;
    });
  },
  methods: {
    operateJump() {
      window.location.href = "https://beian.miit.gov.cn/";
    },
  },
};
</script>

<style lang="scss">
@use "./app.scss";
</style>
