// import Vue from "vue";

import { getCurrentInstance } from 'vue'
// import VConsole from "vconsole"

/**
 * 初始化 app
 * by Jehu
 */
export const initApp = () => {
  const url = location.origin || ''
  if (process.env.NODE_ENV == "development") {
    // new VConsole();
  } else {
    const recycleUrlState = url.indexOf('https://recycle.sffix.cn')
    if (recycleUrlState !== -1) {
      try {
        // new VConsole();
      } catch (e) {
        console.log(e, 'VConsole')
      }
    }
  }
}

/**
 * 全局属性集合
 * @returns {object}
 * by Jehu
 */
export const globalPropertyGather = () => {
  const internalInstance = getCurrentInstance();
  return internalInstance ? internalInstance.appContext.config.globalProperties : {}
}

/**
 * 数组类型校验
 * @param any
 * @returns {array}
 * by Jehu
 */
export const checkArray = (array: any) => {
  return Array.isArray(array) ? array : [];
}

/**
 * 对象类型校验
 * @param any
 * @returns {object|null}
 * by Jehu
 */
export const checkObject = (object: any) => {
  return Object.prototype.toString.call(object) === "[object Object]"
    ? object
    : null;
}

/**
 * 判断是否是IphoneX
 * @returns {boolean}
 * by Jehu
 */
export const isIPhoneX = () => {
  let status = false;
  const u = navigator.userAgent;
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isIOS) {
    if (screen.height >= 812 && screen.width >= 375) {
      //是iphoneX
      status = true;
    }
  }
  return status;
}

/**
 * 判断是否安卓
 * @returns {boolean}
 * by Jehu
 */
export const isAndroid = () => {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
  let status = false;
  if (isAndroid) {
    status = true;
  }
  return status;
}

/**
 * localStorage 本地储存操作
 * @param 【 type 】 write-写入 read-读取 clear-清除
 * by Jehu
 */
export const localStorageTool = (type: string, key: string, value?: any) => {
  if (type === "write") {
    window.localStorage.setItem(key, value);
  }
  if (type === "read") {
    return window.localStorage.getItem(key);
  }
  if (type === "clear") {
    window.localStorage.removeItem(key);
  }
}

/**
 * 时间蹉转自定义格式时间
 * @param 【 time 】 时间搓 【 strText 】 时间格式
 * @returns {string}
 * by Jehu
 */
export const dateRegExp = (time: number, strText: string) => {
  const date = new Date(time);
  // 匹配strText中是否有(y+)(至少一个y)的文本
  // ? 0或1个  + 至少一个 *至少0个
  if (/(y+)/.test(strText)) {
    // RegExp.$1是RegExp的一个属性,指的是与正则表达式匹配的第一个 子匹配(以括号为标志)字符串
    strText = strText.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  const dataType = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (const typeKey in dataType) {
    if (new RegExp(`(${typeKey})`).test(strText)) {
      const typeValue = dataType[typeKey] + "";
      strText = strText.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? typeValue : padLeftZero(typeValue)
      );
    }
  }
  return strText;
}

/**
 * 数字前加 0
 * @param string
 * @returns {string}
 * by Jehu
 */
export const padLeftZero = (string) => {
  const zeroString = string.length > 2 ? string : ("00" + string).substr(string.length);
  return zeroString;
}
