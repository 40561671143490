import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import native from './adapter'
import { initApp } from '@/utils/method'
import { Toast, Dialog } from 'vant'

const app = createApp(App)


app.config.globalProperties.$native = native
app.config.globalProperties.$toast = Toast
app.config.globalProperties.$dialog = Dialog

initApp()

app.use(store).use(router).mount('#app')
